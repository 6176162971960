import { apiUrl, apiVersion } from "../../config";

export async function getUsers () {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/user`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        const users = await response.json();
        if (users) {
            return users;
        }
        throw "Users GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getUsers;
