import { apiUrl, apiVersion } from "../../config";

export async function addCategory (
    category
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/category`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(
                category
            )
        }
    );

    if (response.ok) {
        const category = await response.json();
        if (category) {
            return category;
        }
        throw "CATEGORY ADD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addCategory;
