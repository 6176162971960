import { apiUrl, apiVersion } from "../../config";

export async function authToken (
    tokenToBeTested
) {
    const response = await fetch(
        `${apiUrl}/${apiVersion}/admin/authenticate`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${tokenToBeTested}`
            }
        }
    );

    if (response.ok) {
        let user = await response.json();
        delete user.status;
        return user;
    }

    throw "Authentication failed"
}

export default authToken;
