import { apiUrl, apiVersion } from "../../config";

export async function getOrders () {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${apiUrl}/${apiVersion}/shop/order`,
        {
            "method": "GET",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        const orders = await response.json()
        if (orders) {
            return orders;
        }
        throw "SHOP ORDER GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getOrders;
