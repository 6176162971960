import { apiUrl, apiVersion } from "../../config";

export async function updateCategory (
    categoryId,
    category
) {
    let token = window.localStorage.getItem("marier.admin-token")
    let response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/category/${ categoryId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(
                category
            )
        }
    );

    if (response.ok) {
        const category = await response.json()
        if (category) {
            return category;
        }
        throw "PRODUCT ADD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateCategory;
