import { apiUrl, apiVersion } from "../../config";

export async function updateFAQs (
    faqs
) {
    let token = window.localStorage.getItem("marier.admin-token")
    let response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon/faq`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`,
            },
            body: JSON.stringify(
                faqs
            ),
        }
    )

    if (response.ok) {
        const faqs = await response.json();
        if (faqs) {
            return faqs;
        }
        throw "SALON FAQ UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateFAQs;
