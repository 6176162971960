<template>
    <nav class="navbar is-light">
        <nav class="breadcrumb navbar-start">
            <ul>
                <li v-for="{ name, path } in paths">
                    <router-link v-bind:to="path">
                        {{ name | capitalize }}
                    </router-link>
                </li>
            </ul>
        </nav>
        <div class="navbar-end">
            <div class="navbar-item"
                 v-if="userStatus === 'loggedIn'">
                <b-dropdown position="is-bottom-left">
                    <a class="button" slot="trigger">
                        <span class="icon">
                            <font-awesome-icon v-bind:icon="['far', 'user']"></font-awesome-icon>
                        </span>
                        <span>My Account</span>
                    </a>
                    <b-dropdown-item custom>
                        <span>Logged in as</span>
                        <br>
                        <b>{{ user.email }}</b>
                    </b-dropdown-item>
                    <hr class="dropdown-divider">

                    <b-dropdown-item class="dropdown-menu-item"
                                     v-if="user.verified">
                                <span class="icon has-text-success">
                                    <font-awesome-icon icon="check-circle"></font-awesome-icon>
                                </span>
                        <span>Email verified</span>
                    </b-dropdown-item>

                    <b-dropdown-item class="dropdown-menu-item"
                                     v-if="!user.verified">
                                <span class="icon has-text-danger">
                                    <font-awesome-icon icon="cog"></font-awesome-icon>
                                </span>
                        <span>Verifying email</span>
                    </b-dropdown-item>

                    <hr class="dropdown-divider">
                    <b-dropdown-item has-link>
                        <router-link class="dropdown-menu-item"
                                     to="/account">
                                    <span class="icon">
                                        <font-awesome-icon icon="cog"></font-awesome-icon>
                                    </span>
                            <span>Settings</span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item value="logout" class="dropdown-menu-item"
                                     v-on:click="logout">
                                <span class="icon">
                                    <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                                </span>
                        <span>Logout</span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar",
    data () {
        return {

        }
    },
    computed: {
        user() {
            return this.$store.state.admin.user;
        },
        userStatus() {
            return this.$store.state.admin.status;
        },
        paths () {
            // "" / "shop" / "coupon"

            return this.$route
                .fullPath
                .split("/")
                .map(
                    (part, ind, arr) => {
                        let path = "/";
                        for (let i = 0; i < ind; i++) {
                            path += `${ arr[i + 1] }/`;
                        }
                        return {
                            "name": ind === 0 ? "Home" : part,
                            path
                        };
                    }
                );
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("admin/logout");
        }
    },
}
</script>

<style lang="scss" scoped>
nav.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.30);

    .breadcrumb {
        margin-bottom: 0;
        margin-left: 10px;
    }

    .dropdown-menu-item {
        display: flex !important;
        align-items: center !important;
    }
}
</style>
