import { apiUrl, apiVersion } from "../../config";

export async function addUser (
    { phone }
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${apiUrl}/${apiVersion}/user/register`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(
                {
                    phone,
                    "mode": "phone",
                    "twoFactorAuth": false
                }
            )
        }
    );

    if (response.ok) {
        const user = await response.json();
        if (user) {
            return user;
        }
        throw "Register error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addUser;
