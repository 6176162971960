import addCoupon from "../api/coupon/addCoupon";
import getCoupons from "../api/coupon/getCoupons";
import editCoupon from "../api/coupon/editCoupon";
import deleteCoupon from "../api/coupon/deleteCoupon";

const CouponStore = {
    namespaced: true,
    state: {
        coupons: [],
    },
    getters: {
        coupons (state) {
            return state.coupons;
        },
    },
    mutations: {
        setCoupons (state, coupons) {
            state.coupons = coupons;
        },

        addCoupon (state, coupon) {
            state.coupons.push(coupon);
        },

        editCoupon (state, coupon) {
            state.coupons
                .splice(
                    state.coupons
                        .findIndex(
                            ({ _id }) => _id === coupon._id
                        ),
                    1,
                    coupon
                );
        },

        deleteCoupon (state, couponId) {
            state.coupons
                .splice(
                    state.coupons
                        .findIndex(
                            ({ _id }) => _id === couponId
                        ),
                    1
                );
        },
    },
    actions: {
        async loadCoupon ({ commit }) {
            let coupons;
            try {
                coupons = await getCoupons();
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("setCoupons", coupons);
            return coupons;
        },

        async addCoupon (
            { commit },
            coupon
        ) {
            let response;
            try {
                response = await addCoupon(coupon);
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("addCoupon", response);
            return response;
        },

        async editCoupon (
            { commit },
            coupon,
        ) {
            const { _id, ...rest } = coupon;
            let response;
            try {
                response = await editCoupon(
                    _id,
                    rest
                );
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("editCoupon", response);
            return response;
        },

        async deleteCoupon (
            { commit },
            couponId,
        ) {
            try {
                await deleteCoupon(
                    couponId
                );
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("deleteCoupon", couponId);
        },
    },
}

export default CouponStore;
