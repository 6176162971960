import { apiUrl, apiVersion } from "../../config";

export async function addBranch (branch) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/branch`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(
                branch
            )
        }
    );

    if (response.ok) {
        const branch = await response.json()
        if (branch) {
            branch.coordinate.lat = parseFloat(branch.coordinate.lat);
            branch.coordinate.lng = parseFloat(branch.coordinate.lng);
            return branch;
        }
        throw "BRANCH ADD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addBranch;
