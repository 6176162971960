const mongoObjectIdToDate = {
    install: function (Vue, options) {
        Vue.filter('mongoObjectIdToDate', (value) => {
            let temp = new Date(parseInt(value.substring(0, 8), 16) * 1000)
            return temp.toISOString()
        })
    }
}

export default mongoObjectIdToDate
