import { apiUrl, apiVersion } from "../../config";

export async function getNews (
    start,
    count
) {
    const searchParams = new URLSearchParams({  start, count, });
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall/news?${ searchParams.toString() }`,
        {
            "method": "GET",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": "all",
            }
        }
    );

    if (response.ok) {
        const news = await response.json();
        if (news) {
            return news;
        }
        throw "NEWS GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getNews;
