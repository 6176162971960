const capitalize = {
    install: function (Vue, options) {
        Vue.filter('capitalize', (value) => {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        })
    }
}

export default capitalize
