<template>
    <main class="is-flex">
        <login></login>
        <dashboard></dashboard>
    </main>
</template>

<script>
    import Login from '../views/Login'
    import Dashboard from '../views/Dashboard'

    export default {
        name: 'Default',
        components: {
            Dashboard,
            Login
        }
    }
</script>

<style lang="scss" scoped>
    main {
        width: 100%;
        min-height: 100%;
    }
</style>
