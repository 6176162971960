import { apiUrl, apiVersion } from "../../config";

function hexString(buffer) {
    const byteArray = new Uint8Array(buffer);

    const hexCodes = [...byteArray].map(
        value => {
            const hexCode = value.toString(16);
            return hexCode.padStart(2, "0");
        }
    );

    return hexCodes.join("");
}

function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    return window.crypto.subtle.digest("SHA-512", data);
}


export async function login (
    { email, password, },
) {
    const hashed = await digestMessage(password);
    const response = await fetch(
        `${apiUrl}/${apiVersion}/admin/login`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(
                {
                    email,
                    "password": hexString(hashed),
                    "mode": "email"
                }
            )
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw await response.json();
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default login;
