import { apiUrl, apiNewVersion } from "../../config";

export async function editCoupon (
    couponId,
    coupon,
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiNewVersion }/coupon/${ couponId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            "body": JSON.stringify(
                coupon
            ),
        }
    )

    if (response.ok) {
        const coupon = await response.json();
        if (coupon) {
            return coupon;
        }
        throw "COUPON UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default editCoupon;
