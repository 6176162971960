import { apiUrl, apiVersion } from "../../config";

export async function addNews (
    news
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const body = new FormData();
    for (const name in news) {
        if (["thumbnail", "titleImage", "images"].includes(name)) {
            if (news[name]) {
                body.append(`${name}`, news[name]);
            }
        } else {
            if (news[name] !== undefined) {
                body.append(name, JSON.stringify(news[name]));
            }
        }
    }

    const response = await fetch(
        `${apiUrl}/${apiVersion}/mall/news`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${ token }`
            },
            body,
        }
    );

    if (response.ok) {
        let testimonial = await response.json();
        if (testimonial) {
            return testimonial;
        }
        throw "News ADD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addNews;
