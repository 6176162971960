<template>
    <div class="log-in columns is-gapless">
        <div class="background"></div>
        <div class="column is-two-thirds-tablet is-three-quarters-widescreen is-flex logo">
            <div class="wrapper">
                <img src="../assets/marier_logo.svg" alt="MARIER SKINCARE LOGO">
            </div>
        </div>
        <log-in-column class="column"></log-in-column>
    </div>
</template>

<script>
    import LogInColumn from "../components/layouts/LogInColumn"

    export default {
        name: "Login",
        components: {
            LogInColumn
        }
    }
</script>

<style lang="scss" scoped>
    @import "~bulma/sass/utilities/_all";

    .log-in {
        width: 100%;
        margin-bottom: 0 !important;

        @include mobile {
            display: flex;
            flex-direction: column;
        }

        & > .background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;

            background-image: url("../assets/background.png");
            background-size: cover;
            background-repeat: no-repeat;
        }

        & > .logo {
            justify-content: center;
            align-items: center;

            @include mobile {
                flex: 1;
            }

            & > .wrapper {
                max-width: 80%;
                padding: 40px;
                background-color: hsla(0, 0%, 100%, .75);

                @include mobile {
                    max-width: 90%;
                }

                & > img {
                    width: 100%;
                    max-width: 550px;
                }
            }
        }

        & > .column:last-child {
            @include mobile {
                flex: 2;
            }
        }
    }
</style>
