import { apiUrl, apiVersion } from "../../config";

export async function updateTreatment (
    treatmentId,
    treatment
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const body = new FormData();
    for (const name in treatment) {
        if (["cover"].includes(name)) {
            if (treatment[name]) {
                body.append(`${name}`, treatment[name]);
            }
        }
        if (["images"].includes(name)) {
            if (
                Array.isArray(treatment[name]) &&
                treatment[name].length > 0
            ) {
                body.append(`${name}`, treatment[name][0]);
            }
        }
        if (treatment[name] !== undefined) {
            body.append(name, JSON.stringify(treatment[name]));
        }
    }
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon/treatment/${ treatmentId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body,
        }
    );

    if (response.ok) {
        const treatment = await response.json();
        if (treatment) {
            return treatment;
        }
        throw "TREATMENT UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateTreatment;
