import SalonAPI from '../api/salon'
import ShopAPI from '../api/shop'

const SalonStore = {
    namespaced: true,
    state: {
        salon: {
            categories: [],
            series: [],
            treatments: [],
            faqs: [],
        },

        categories: [],
        series: [],
        treatments: [],
        faqs: [],
        paymentMethods: undefined,
        notificationOptions: undefined,

        bookings: []
    },
    getters: {
        bookings (state) {
            return state.bookings;
        },
    },
    mutations: {
        setSalon (
            state,
            salon,
        ) {
            const { categories, series, treatments, faqs, paymentMethods, notificationOptions,  } = salon;

            state.salon = salon;

            state.categories = categories;
            state.series = series;
            state.treatments = treatments;
            state.faqs = faqs;
            state.paymentMethods = paymentMethods;
            state.notificationOptions = notificationOptions;
        },

        setOrders (
            state,
            bookings
        ) {
            state.bookings = bookings
        },

        updateSalon (
            state,
            updatedShop
        ) {
            state.salon = updatedShop
        },

        addCategory (state, category) {
            state.salon.categories.push(category)
        },

        updateCategory (state, updatedCategory) {
            state.salon.categories.splice(state.salon.categories.findIndex(category => category._id === updatedCategory._id), 1, updatedCategory)
        },

        deleteCategory(state, categoryId) {
            state.salon.categories.splice(state.salon.categories.findIndex(category => category._id === categoryId), 1)
        },

        addSeries(state, series) {
            state.salon.series.push(series)
        },

        updateSeries(state, updatedSeries) {
            state.salon.series.splice(state.salon.series.findIndex(series => series._id === updatedSeries._id), 1, updatedSeries)
        },

        deleteSeries(state, seriesId) {
            state.salon.series.splice(state.salon.series.findIndex(series => series._id === seriesId), 1)
        },

        addTreatment(state, treatment) {
            state.salon.treatments.push(treatment)
        },

        updateTreatment(state, updatedTreatment) {
            state.salon.treatments.splice(state.salon.treatments.findIndex(treatment => treatment._id === updatedTreatment._id), 1, updatedTreatment)
        },

        deleteTreatment(state, treatmentId) {
            state.salon.treatments.splice(state.salon.treatments.findIndex(treatment => treatment._id === treatmentId), 1)
        },

        setFaqs(state, faqs) {
            state.salon.faqs = faqs
        }
    },
    actions: {
        async loadSalon(context) {
            let salon
            try {
                salon = await SalonAPI
                    .getSalon()
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('setSalon', salon)
            return salon
        },

        async loadOrders(context) {
            let orders
            try {
                orders = await SalonAPI
                    .getOrders()
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('setOrders', orders)
            return orders
        },

        async updateSalon(context, salon) {
            salon = Object.assign({}, salon)
            let result
            try {
                result = await SalonAPI
                    .updateSalon(
                        salon
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('updateSalon', result)
            return result
        },

        async addCategory(context, category) {
            let result
            try {
                result = await SalonAPI
                    .addCategory(
                        category
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('addCategory', result)
            return result
        },

        async updateCategory(context, category) {
            let result
            let categoryId = category._id
            delete category._id
            try {
                result = await SalonAPI
                    .updateCategory(
                        categoryId,
                        category
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('updateCategory', result)
            return result
        },

        async deleteCategory(context, categoryId) {
            try {
                await SalonAPI
                    .deleteCategory(
                        categoryId
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('deleteCategory', categoryId)
        },

        async addSeries(context, series) {
            let result
            try {
                result = await SalonAPI
                    .addSeries(
                        series
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('addSeries', result)
            return result
        },

        async updateSeries(context, series) {
            let seriesId = series._id
            delete series._id
            let result
            try {
                result = await SalonAPI
                    .updateSeries(
                        seriesId,
                        series
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('updateSeries', result)
        },

        async deleteSeries(context, seriesId) {
            let result
            try {
                result = await SalonAPI
                    .deleteSeries(
                        seriesId
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('deleteSeries', seriesId)
        },

        async addTreatment(context, treatment) {
            let result
            try {
                result = await SalonAPI
                    .addTreatment(
                        treatment
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('addTreatment', result)
            return result
        },

        async updateTreatment(context, treatment) {
            let result
            let treatmentId = treatment._id
            delete treatment._id
            try {
                result = await SalonAPI
                    .updateTreatment(
                        treatmentId,
                        treatment
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('updateTreatment', result)
            return result
        },

        async deleteTreatment(context, treatmentId) {
            try {
                await SalonAPI
                    .deleteTreatment(
                        treatmentId
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('deleteTreatment', treatmentId)
        },

        async updateFAQs(context, faqs) {
            let result
            try {
                result = await SalonAPI
                    .updateFAQs(
                        faqs
                    )
            } catch (e) {
                console.error(e)
                throw e
            }
            context.commit('setFaqs', result)
            return result
        }
    }
}

export default SalonStore
