import { apiUrl, apiVersion } from "../../config";

export async function getMall () {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${apiUrl}/${apiVersion}/mall`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
    );

    if (response.ok) {
        const mall = await response.json();
        if (mall) {
            return mall
        }
        throw "MALL GET error"
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error"
}

export default getMall;
