import getUsers from "./getUsers";
import addUser from "./addUser";
import editUser from "./editUser";
import deleteUser from "./deleteUser";

export default {
    getUsers,
    addUser,
    editUser,
    deleteUser,
}
