import Vue from "vue";
import Vuex from "vuex";

import admin from "./AdminStore";
import users from "./UsersStore";
import mall from "./MallStore";
import shop from "./ShopStore";
import salon from "./SalonStore";
import coupon from "./CouponStore";
import layout from "./LayoutStore";

Vue.use(Vuex);

export default new Vuex.Store(
    {
        "modules": {
            layout,
            admin,
            users,
            mall,
            shop,
            salon,
            coupon,
        }
    }
);
