const LayoutStore = {
    namespaced: true,
    state: {
        sideBarOpen: false,
        offline: false,

        updateAvailable: false,
        updatedServiceWorker: null,
    },
    mutations: {
        openSideBar(state) {
            state.sideBarOpen = true;
        },

        closeSideBar(state) {
            state.sideBarOpen = false;
        },

        updateAvailable(state, updatedServiceWorker) {
            state.updateAvailable = true;
            state.updatedServiceWorker = updatedServiceWorker;
        },

        online(state) {
            state.offline = false;
        },

        offline(state) {
            state.offline = true;
        }
    },
};

export default LayoutStore;
