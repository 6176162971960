import { apiUrl, apiVersion } from "../../config";

export async function updateSeries (
    seriesId,
    series
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const body = new FormData();
    for (const name in series) {
        if (["cover"].includes(name)) {
            if (series[name]) {
                body.append(`${name}`, series[name])
            }
        } else {
            if (series[name] !== undefined) {
                body.append(name, JSON.stringify(series[name]))
            }
        }
    }
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon/series/${seriesId}`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body,
        }
    )

    if (response.ok) {
        const series = await response.json();
        if (series) {
            return series;
        }
        throw "Treatment Series UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateSeries;
