import { apiUrl, apiVersion } from "../../config";

export async function setCampaignEnquiryStatus (
    campaignId,
    enquiryId,
    status
) {
    const token = window.localStorage.getItem("marier.admin-token")
    const response = await fetch(
        `${apiUrl}/${apiVersion}/mall/campaign/${ campaignId }/enquiry/${ enquiryId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(
                {
                    status,
                }
            ),
        }
    );

    if (response.ok) {
        const enquiries = await response.json();
        if (enquiries) {
            return enquiries;
        }
        throw "CAMPAIGN ENQUIRY SET STATUS error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default setCampaignEnquiryStatus;
