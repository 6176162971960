import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import DefaultLayout from "../layouts/Default.vue";

const routes = [
    {
        path: "/",
        component: DefaultLayout,
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("../views/Home.vue")
            },
            {
                path: "/account",
                name: "account",
                component: () => import("../views/Account.vue")
            },
            {
                path: "/admin",
                name: "admins",
                component: () => import("../views/Admins.vue")
            },
            {
                path: "/employee",
                name: "employees",
                components: {
                    default: () => import("../views/Employee.vue")
                },
                children: [
                    {
                        path: "",
                        components: {
                            default: () => import("../components/Employee/EmployeeTable.vue")
                        }
                    },
                    {
                        path: ":id",
                        components: {
                            default: () => import("../components/Employee/EmployeeDetails.vue")
                        }
                    }
                ],
            },
            {
                path: "/inbox",
                name: "inbox",
                component: () => import("../views/Inbox.vue")
            },
            {
                path: "/user",
                meta: {
                    authRequired: true
                },
                components: {
                    default: () => import("../views/Users.vue")
                },
                children: [
                    {
                        path: "",
                        name: "user-list",
                        components: {
                            default: () => import("../components/Users/UserTable.vue")
                        }
                    },
                    {
                        path: "category",
                        components: {
                            default: () => import("../components/Users/UserCategories.vue")
                        }
                    },
                    {
                        path: ":id",
                        name: "user-details",
                        components: {
                            default: () => import("../components/Users/UserDetails.vue")
                        }
                    }
                ]
            },
            {
                path: "/branches",
                name: "shop-branches",
                components: {
                    default: () => import("../views/Branches.vue")
                }
            },
            {
                path: "/shop",
                component: () => import("../views/Shop.vue"),
                children: [
                    {
                        path: "",
                        name: "shop-settings",
                        component: () => import("../components/Shop/Settings.vue"),
                    },
                    {
                        path: "delivery",
                        name: "shop-delivery",
                        component: () => import("../components/Shop/DeliveryClasses.vue"),
                    },
                    {
                        path: "delivery/new",
                        name: "add-shop-delivery",
                        component: () => import("../components/Shop/DeliveryClass.vue"),
                    },
                    {
                        path: "delivery/:classId",
                        name: "edit-shop-delivery",
                        component: () => import("../components/Shop/DeliveryClass.vue"),
                    },
                    {
                        path: "categories",
                        name: "shop-categories",
                        component: () => import("../components/Shop/Categories.vue"),
                    },
                    {
                        path: "series",
                        name: "shop-series",
                        component: () => import("../components/Shop/Series.vue"),
                    },
                    {
                        path: "series/new",
                        name: "add-product-series",
                        component: () => import("../components/Shop/SeriesDetails.vue"),
                    },
                    {
                        path: "series/:seriesId",
                        name: "edit-product-series",
                        component: () => import("../components/Shop/SeriesDetails.vue"),
                        props: true,
                    },
                    {
                        path: "products",
                        name: "products",
                        component: () => import("../components/Shop/Products.vue"),
                    },
                    {
                        path: "products/new",
                        name: "add-product",
                        component: () => import("../components/Shop/ProductDetails.vue"),
                    },
                    {
                        path: "products/:productId",
                        name: "edit-product",
                        component: () => import("../components/Shop/ProductDetails.vue"),
                        props: true,
                    },
                    {
                        path: "coupons",
                        name: "coupons",
                        component: () => import("../components/Shop/Coupons.vue"),
                    },
                    {
                        path: "coupons/new",
                        name: "add-coupon",
                        component: () => import("../components/Shop/CouponDetails.vue"),
                    },
                    {
                        path: "coupons/:couponId",
                        name: "edit-coupon",
                        component: () => import("../components/Shop/CouponDetails.vue"),
                        props: true,
                    },
                    {
                        path: "orders",
                        name: "shop-orders",
                        component: () => import("../components/Shop/Orders.vue"),
                    },
                    {
                        path: "orders/:orderId",
                        name: "shop-order-details",
                        component: () => import("../components/Shop/Orders.vue"),
                        props: true,
                    }
                ]
            },
            {
                path: "/salon",
                components: {
                    default: () => import("../views/Salon.vue")
                },
                children: [
                    {
                        path: "",
                        name: "salon-settings",
                        components: {
                            default: () => import("../components/Salon/Settings.vue")
                        }
                    },
                    {
                        path: "faqs",
                        name: "salon-faqs",
                        components: {
                            default: () => import("../components/Salon/FAQ.vue")
                        }
                    },
                    {
                        path: "categories",
                        name: "salon-categories",
                        components: {
                            default: () => import("../components/Salon/Categories.vue")
                        }
                    },
                    {
                        path: "series",
                        name: "salon-series",
                        components: {
                            default: () => import("../components/Salon/Series.vue")
                        }
                    },
                    {
                        path: "series/new",
                        name: "add-treatment-series",
                        components: {
                            default: () => import("../components/Salon/SeriesDetails.vue")
                        }
                    },
                    {
                        path: "series/:seriesId",
                        name: "edit-treatment-series",
                        components: {
                            default: () => import("../components/Salon/SeriesDetails.vue")
                        },
                        props: true,
                    },
                    {
                        path: "treatments/new",
                        name: "add-treatment",
                        components: {
                            default: () => import("../views/salon/TreatmentDetails.vue")
                        }
                    },
                    {
                        path: "treatments/:treatmentId",
                        name: "edit-treatment",
                        components: {
                            default: () => import("../views/salon/TreatmentDetails.vue")
                        },
                        props: true,
                    },
                    {
                        path: "bookings",
                        name: "salon-bookings",
                        components: {
                            default: () => import("../views/salon/Bookings.vue")
                        },
                    },
                    {
                        path: "bookings/:bookingId",
                        name: "salon-booking-details",
                        components: {
                            default: () => import("../views/salon/Bookings.vue")
                        },
                        props: true,
                    }
                ]
            },
            {
                path: "/campaign",
                name: "campaign",
                components: {
                    default: () => import("../views/Campaign.vue")
                },
                children: [
                    {
                        path: "",
                        name: "campaign-list",
                        components: {
                            default: () => import("../components/Campaign/List.vue")
                        }
                    },
                    {
                        path: "settings",
                        name: "campaign-settings",
                        components: {
                            default: () => import("../components/Campaign/Settings.vue")
                        }
                    },
                    {
                        path: "enquiries",
                        name: "campaign-enquiries",
                        components: {
                            default: () => import("../components/Campaign/Enquiries.vue")
                        }
                    }
                ]
            },
            {
                path: "/page",
                name: "page",
                component: () => import("../views/Page.vue"),
                children: [
                    {
                        path: "home",
                        name: "page-home",
                        components: {
                            default: () => import("../components/page/Home.vue")
                        }
                    },
                    {
                        path: "blog",
                        name: "page-blog",
                        components: {
                            default: () => import("../components/page/Blog.vue")
                        }
                    },
                    {
                        path: "blog/new",
                        name: "add-page-blog",
                        components: {
                            default: () => import("../components/page/BlogDetails.vue")
                        },
                    },
                    {
                        path: "blog/:blogId",
                        name: "edit-page-blog",
                        components: {
                            default: () => import("../components/page/BlogDetails.vue")
                        },
                        props: {
                            default: true,
                        },
                    },
                    {
                        path: "news",
                        name: "page-news",
                        components: {
                            default: () => import("../components/page/News.vue")
                        }
                    },
                    {
                        path: "news/new",
                        name: "add-page-news",
                        components: {
                            default: () => import("../components/page/NewsDetails.vue")
                        }
                    },
                    {
                        path: "news/:newsId",
                        name: "edit-page-news",
                        components: {
                            default: () => import("../components/page/NewsDetails.vue")
                        },
                        props: {
                            default: true,
                        },
                    },
                    {
                        path: "about",
                        name: "page-about",
                        components: {
                            default: () => import("../components/Salon/Settings.vue")
                        }
                    },
                    {
                        path: "privacy",
                        name: "page-privacy",
                        components: {
                            default: () => import("../components/Salon/Settings.vue")
                        }
                    },
                    {
                        path: "terms",
                        name: "page-terms",
                        components: {
                            default: () => import("../components/Salon/Settings.vue")
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "*",
        redirect: "/",
    },
];

export default new Router({
    "mode": "history",
    "base": process.env.BASE_URL,
    routes,
});
