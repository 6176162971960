import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stores/index";

import Buefy from "buefy";
Vue.use(Buefy);

import vueDateFnsFormatter from "./plugins/date-fns-formatter/index";
Vue.use(vueDateFnsFormatter);

import vueDateFnsRelativeFormatter from "./plugins/date-fns-relative-formatter/index";
Vue.use(vueDateFnsRelativeFormatter);

import VueProgressiveImage from "vue-progressive-image";
Vue.use(VueProgressiveImage);

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(
    VueGoogleMaps,
    {
        load: {
            key: "AIzaSyBdiV1CWzicd4YYO6NSQIu9dV3GHcePoSg",
            libraries: "places"
        }
    }
);

//import VueAwesomeSwiper from "vue-awesome-swiper";
//import "swiper/dist/css/swiper.css";
//Vue.use(VueAwesomeSwiper);

import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
Vue.use(Croppa);

import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);

import VueSanitizeHtml from "./plugins/sanitize-html/index";
Vue.use(VueSanitizeHtml);

import "./init/font-awesome";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import "@mdi/font/css/materialdesignicons.min.css";

import lineClamp from "vue-line-clamp";
Vue.use(lineClamp, {});

import {
    baseUrl,
    apiUrl,
    apiVersion
} from "./config";
import VueConfigurator from "./plugins/configurator/index";
Vue.use(
    VueConfigurator,
    {
        baseUrl,
        apiUrl,
        apiVersion
    }
);

import {
    mongoObjectIdToDate,
    capitalize,
    currency
} from "./plugins/filters";
Vue.use(mongoObjectIdToDate);
Vue.use(capitalize);
Vue.use(currency);

import "./registerServiceWorker";

Vue.config.productionTip = false

new Vue(
    {
        router,
        store,
        "render": h => h(App)
    }
)
    .$mount("#app");
