import Vue from "vue";

import getMall from "../api/mall/getMall";
import updateMall from "../api/mall/updateMall";

//import getTestimonials from "../api/mall/getTestimonials";
//import addTestimonial from "../api/mall/addTestimonial";
//import updateTestimonial from "../api/mall/updateTestimonial";
//import deleteTestimonial from "../api/mall/deleteTestimonial";

import getNews from "../api/mall/getNews";
import addNews from "../api/mall/addNews";
import updateNews from "../api/mall/updateNews";
import deleteNews from "../api/mall/deleteNews";

import getBlog from "../api/mall/getBlog";
import addBlog from "../api/mall/addBlog";
import updateBlog from "../api/mall/updateBlog";
import deleteBlog from "../api/mall/deleteBlog";

//import addBranch from "../api/mall/addBranch";
//import updateBranch from "../api/mall/updateBranch";
//import deleteBranch from "../api/mall/deleteBranch";

import getCampaigns from "../api/mall/getCampaigns";
import setCampaignEnquiryStatus from "../api/mall/setCampaignEnquiryStatus";
import getCampaignEnquiries from "../api/mall/getCampaignEnquiries";
import getMessages from "../api/mall/getMessages";

const MallStore = {
    namespaced: true,
    state: {
        mall: {
            branches: [],
            testimonials: [],
            news: [],
            inbox: []
        },

        branches: [],
        featurettes: [],
        inbox: [],

        news: [],
        blog: [],
        testimonials: [],

        campaigns: [],
        enquiries: {},
    },
    mutations: {
        setMall(
            state,
            { branches, featurettes, inbox, news, blog, testimonials,  },
        ) {
            state.branches = branches;
            state.featurettes = featurettes;
            state.inbox = inbox;
            //state.news = news;
            //state.blog = blog;
            state.testimonials = testimonials;
        },

        setBlog (
            state,
            blog
        ) {
            state.blog = blog;
        },

        addBlog (
            state,
            blog
        ) {
            state.blog
                .unshift(
                    blog
                );
        },

        updateBlog (
            state,
            updatedBlog
        ) {
            state.blog
                .splice(
                    state
                        .blog
                        .findIndex(
                            ({ _id }) => _id === updatedBlog._id
                        ),
                    1,
                    updatedBlog
                );
        },

        deleteBlog (
            state,
            blogId
        ) {
            state
                .blog
                .splice(
                    state.blog
                        .findIndex(
                            ({ _id }) => _id === blogId
                        ),
                    1
                );
        },

        setNews (
            state,
            news
        ) {
            state.news = news;
        },

        addNews (
            state,
            news
        ) {
            state
                .news
                .unshift(
                    news
                );
        },

        updateNews (
            state,
            updatedNews
        ) {
            state
                .news
                .splice(
                    state.news
                        .findIndex(
                            ({ _id }) => _id === updatedNews._id
                        ),
                    1,
                    updatedNews
                );
        },

        deleteNews (
            state,
            newsId
        ) {
            state.news
                .splice(
                    state.news
                        .findIndex(
                            ({ _id }) => _id === newsId
                        ),
                    1
                );
        },

        setCampaigns (
            state,
            campaigns
        ) {
            state.campaigns = campaigns
        },

        setCampaignEnquiries (
            state,
            { campaignId, enquiries }
        ) {
            Vue.set(
                state.enquiries,
                campaignId,
                enquiries
            );
        },

        updateEnquiry (
            state,
            { campaignId, enquiry, }
        ) {
            const target = state.campaigns
                .find(
                    ({ _id }) => _id === campaignId
                );
            if (target) {
                target.enquiries
                    .splice(
                        target.enquiries
                            .findIndex(
                                ({ _id }) => _id === enquiry._id
                            ),
                        1,
                        enquiry
                    );
            }
        },

        setMessages (
            state,
            messages
        ) {
            state.inbox = Object.freeze(messages);
        },
    },
    actions: {
        async loadMall (
            { commit, },
        ) {
            let mall;
            try {
                mall = await getMall();
            } catch (e) {
                throw e;
            }
            commit("setMall", mall);
            return mall;
        },

        async updateMall(
            { commit, },
            update
        ) {
            let mall
            try {
                mall = await updateMall(
                    update
                );
            } catch (e) {
                throw e;
            }
            commit("setMall", mall);
            return mall;
        },

        /*async addBranch (
            { commit, },
            branch
        ) {
            let result
            try {
                result = await addBranch(
                    branch
                );
            } catch (e) {
                throw e;
            }
            commit("addBranch", result);
        },*/

        /*async updateBranch (
            { commit, },
            {  _id, ...rest },
        ) {
            let result
            try {
                result = await updateBranch(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateBranch", result);
        },*/

        /*async deleteBranch (
            { commit, },
            branchId
        ) {
            try {
                await deleteBranch(
                    branchId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteBranch", branchId);
        },*/

        /*async loadTestimonials(
            { commit, },
        ) {
            let testimonials
            try {
                testimonials = await getTestimonials();
            } catch (e) {
                throw e;
            }
            commit("setTestimonials", testimonials);
            return testimonials;
        },*/

        /*async addTestimonial (
            { commit, },
            testimonial
        ) {
            let result
            try {
                result = await addTestimonial(
                    testimonial
                );
            } catch (e) {
                throw e;
            }
            commit("addTestimonial", result);
        },*/

        /*async updateTestimonial (
            { commit, },
            {  _id, ...rest },
        ) {
            let result
            try {
                result = await updateTestimonial(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateTestimonial", result);
        },*/

        /*async deleteTestimonial (
            { commit, },
            testimonialId
        ) {
            let result
            try {
                result = await deleteTestimonial(
                    testimonialId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteTestimonial", testimonialId);
        },*/

        async loadNews(
            { commit, },
        ) {
            let news
            try {
                news = await getNews(
                    0,
                    999
                );
            } catch (e) {
                throw e;
            }
            commit("setNews", news);
            return news;
        },

        async addNews (
            { commit, },
            news
        ) {
            let result
            try {
                result = await addNews(
                    news
                );
            } catch (e) {
                throw e;
            }
            commit("addNews", result);
        },

        async updateNews (
            { commit, },
            {  _id, ...rest },
        ) {
            let result
            try {
                result = await updateNews(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateNews", result);
        },

        async deleteNews (
            { commit, },
            newsId
        ) {
            try {
                await deleteNews(
                    newsId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteNews", newsId);
        },

        async loadBlog(
            { commit, },
        ) {
            let articles
            try {
                articles = await getBlog(
                    0,
                    999
                );
            } catch (e) {
                throw e;
            }
            commit("setBlog", articles);
            return articles;
        },

        async addBlog (
            { commit, },
            news
        ) {
            let result
            try {
                result = await addBlog(
                    news
                );
            } catch (e) {
                throw e;
            }
            commit("addBlog", result);
        },

        async updateBlog (
            { commit, },
            { _id, ...rest }
        ) {
            let result
            try {
                result = await updateBlog(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateBlog", result);
        },

        async deleteBlog (
            { commit, },
            blogId,
        ) {
            let result
            try {
                result = await deleteBlog(
                    blogId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteBlog", blogId);
        },

        async loadCampaigns (
            { commit, },
        ) {
            let campaigns
            try {
                campaigns = await getCampaigns();
            } catch (e) {
                throw e;
            }
            commit("setCampaigns", campaigns);
            return campaigns;
        },

        async loadCampaignEnquiries (
            { commit, },
            campaignId,
        ) {
            let enquiries;
            try {
                enquiries = await getCampaignEnquiries(
                    campaignId,
                );
            } catch (e) {
                throw e;
            }
            commit(
                "setCampaignEnquiries",
                { campaignId, enquiries, }
            );
            return enquiries;
        },

        async updateCampaignEnquiryStatus(
            { commit, },
            { campaignId, enquiryId, status, }
        ) {
            let enquiry
            try {
                enquiry = await setCampaignEnquiryStatus(
                    campaignId,
                    enquiryId,
                    status
                );
            } catch (e) {
                throw e;
            }
            commit(
                "updateEnquiry",
                {
                    campaignId,
                    enquiry
                }
            );
            return enquiry;
        },

        async loadMessage (
            { commit, },
        ) {
            let messages
            try {
                messages = await getMessages(
                    0,
                    999
                );
            } catch (e) {
                throw e;
            }
            commit("setMessages", messages);
            return messages;
        }
    }
}

export default MallStore
