import { apiUrl, apiVersion } from "../../config";

export async function updateBranch (
    branchId,
    branch
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${apiUrl}/${apiVersion}/shop/branch/${ branchId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(
                branch
            )
        }
    );

    if (response.ok) {
        const branch = await response.json();
        if (branch) {
            branch.coordinate.lat = parseFloat(branch.coordinate.lat);
            branch.coordinate.lng = parseFloat(branch.coordinate.lng);
            return branch;
        }
        throw "BRANCH UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateBranch;
