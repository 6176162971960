<template>
    <aside class="side-bar menu">
        <nav class="panel is-radiusless">
            <div class="panel-block is-radiusless brand">
                <span>MARIER</span>
            </div>
            <!-- <div class="panel-block">
                <button class="button is-rounded">
                    <font-awesome-icon icon="cog"></font-awesome-icon>
                </button>
                <button class="button is-rounded"
                        v-on:click="logout">
                    <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
                </button>
            </div> -->
        </nav>
        <nav class="panel is-radiusless">
            <router-link class="panel-block is-radiusless"
                         exact-active-class="is-active"
                         to="/">
                <div class="content">
                    <div class="item">
                        Home
                    </div>
                </div>
            </router-link>

            <router-link class="panel-block"
                         active-class="is-active"
                         to="/admin">
                <div class="content">
                    <div class="item">
                        Admins
                    </div>
                </div>
            </router-link>

            <router-link class="panel-block"
                         active-class="is-active"
                         to="/employee">
                <div class="content">
                    <div class="item">
                        Employees
                    </div>
                </div>
            </router-link>

            <router-link class="panel-block"
                         active-class="is-active"
                         to="/user">
                <div class="content">
                    <div class="item">
                        Customers
                    </div>
                </div>
            </router-link>

            <router-link class="panel-block"
                         active-class="is-active"
                         to="/inbox">
                <div class="content">
                    <div class="item">
                        Inbox
                    </div>
                </div>
            </router-link>

            <router-link router-link class="panel-block"
                         active-class="is-active"
                         to="/branches">
                <div class="content">
                    <div class="item">
                        Branches
                    </div>
                </div>
            </router-link>

            <a class="panel-block"
               v-bind:class="{ 'is-active': selectedSubItems.shop }">
                <div class="content">
                    <div class="item"
                         v-on:click="toggleSubItems('shop')">
                        <div class="item-icon">
                            <font-awesome-icon class="icon" icon="angle-down"></font-awesome-icon>
                        </div>
                        Shop
                    </div>
                    <nav class="panel"
                         v-bind:class="{ 'is-active': expandSubItems.shop }">
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     exact
                                     to="/shop">
                            <div class="content">
                                Settings
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     to="/shop/delivery">
                            <div class="content">
                                Delivery
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     to="/shop/categories">
                            <div class="content">
                                Categories
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     to="/shop/series">
                            <div class="content">
                                Series
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     to="/shop/products">
                            <div class="content">
                                Products
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     to="/shop/coupons">
                            <div class="content">
                                Coupons
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     v-bind:class="{ 'is-active': ['Shop-orders', 'order-details'].includes($route.name) }"
                                     active-class="is-active"
                                     to="/shop/orders">
                            <div class="content">
                                Orders
                            </div>
                        </router-link>
                    </nav>
                </div>
            </a>
            <a class="panel-block"
               v-bind:class="{ 'is-active': selectedSubItems.salon }">
                <div class="content">
                    <div class="item"
                         v-on:click="toggleSubItems('salon')">
                        <div class="item-icon">
                            <font-awesome-icon class="icon" icon="angle-down"></font-awesome-icon>
                        </div>
                        Salon
                    </div>
                    <nav class="panel"
                         v-bind:class="{ 'is-active': expandSubItems.salon }">
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     exact
                                     to="/salon">
                            <div class="content">
                                Settings
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     v-bind:class="{ 'is-active': ['Salon-faqs'].includes($route.name) }"
                                     active-class="is-active"
                                     to="/salon/faqs">
                            <div class="content">
                                FAQ
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     to="/salon/categories">
                            <div class="content">
                                Categories
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     v-bind:class="{ 'is-active': ['add-treatment', 'edit-treatment'].includes($route.name) }"
                                     active-class="is-active"
                                     to="/salon/series">
                            <div class="content">
                                Treatments
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     v-bind:class="{ 'is-active': ['Salon-bookings', 'booking-details'].includes($route.name) }"
                                     active-class="is-active"
                                     to="/salon/bookings">
                            <div class="content">
                                Bookings
                            </div>
                        </router-link>
                    </nav>
                </div>
            </a>
            <a class="panel-block"
               v-bind:class="{ 'is-active': selectedSubItems.campaigns }">
                <div class="content">
                    <div class="item"
                         v-on:click="toggleSubItems('campaigns')">
                        <div class="item-icon">
                            <font-awesome-icon class="icon" icon="angle-down"></font-awesome-icon>
                        </div>
                        Campaigns
                    </div>
                    <nav class="panel"
                         v-bind:class="{ 'is-active': expandSubItems.campaigns }">
                        <router-link class="panel-block"
                                     active-class="is-active"
                                     exact
                                     to="/campaign">
                            <div class="content">
                                Settings
                            </div>
                        </router-link>
                        <router-link class="panel-block"
                                     v-bind:class="{ 'is-active': ['Campaign-enquiries'].includes($route.name) }"
                                     active-class="is-active"
                                     to="/campaign/enquiries">
                            <div class="content">
                                Enquiries
                            </div>
                        </router-link>
                    </nav>
                </div>
            </a>
            <a class="panel-block is-radiusless"
               v-bind:class="{ 'is-active': selectedSubItems.pages }">
                <div class="content">
                    <div class="item"
                         v-on:click="toggleSubItems('pages')">
                        <div class="item-icon">
                            <font-awesome-icon class="icon" icon="angle-down"></font-awesome-icon>
                        </div>
                        Page Content
                    </div>
                    <nav class="panel"
                         v-bind:class="{ 'is-active': expandSubItems.pages }">
                        <router-link class="panel-block" active-class="is-active" to="/page/home">
                            <div class="content">
                                Home
                            </div>
                        </router-link>
                        <router-link class="panel-block" active-class="is-active" to="/page/news">
                            <div class="content">
                                News
                            </div>
                        </router-link>
                        <router-link class="panel-block" active-class="is-active" to="/page/blog">
                            <div class="content">
                                Blog
                            </div>
                        </router-link>
                        <router-link class="panel-block" active-class="is-active" to="/page/about">
                            <div class="content">
                                About Marier
                            </div>
                        </router-link>
                        <router-link class="panel-block" active-class="is-active" to="/page/privacy">
                            <div class="content">
                                Privacy
                            </div>
                        </router-link>
                        <router-link class="panel-block" active-class="is-active" to="/page/terms">
                            <div class="content">
                                Terms and Conditions
                            </div>
                        </router-link>
                    </nav>
                </div>
            </a>
        </nav>
    </aside>
</template>

<script>
export default {
    name: 'SideBar',
    data() {
        return {
            expandSubItems: {
                shop: false,
                salon: false,
                campaigns: false,
                pages: false
            },

            selectedSubItems: {
                shop: false,
                salon: false,
                campaigns: false,
                pages: false
            }
        }
    },
    methods: {
        toggleSubItems(target) {
            Object.keys(this.expandSubItems)
                .filter(
                    subItem => subItem !== target
                )
                .forEach(
                    subItem => this.expandSubItems[subItem] = false
                )
            if (target) {
                this.expandSubItems[target] = !this.expandSubItems[target]
            }
        },
        goToView(target) {
            Object.keys(this.expandSubItems)
                .forEach(
                    subItem => this.expandSubItems[subItem] = false
                )
            this.$router.push(target)
        },
        logout() {
            this.$store.dispatch('admin/logout')
        }
    },
    watch: {
        /* "$route" (newVal) {
                    console.log(newVal);
                } */
    }
}
</script>

<style lang="scss" scoped>
aside.side-bar {
    position: relative;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;

    & > .panel {
        &:first-child {
            margin-bottom: 0;
        }

        &:last-child {
            height: 100%;
            box-shadow: inset 2px 2px 15px rgba(0, 0, 0, 0.5);
        }

        .panel-block {
            border: none;
            display: flex;
            padding: 0;

            &.brand {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding-top: 7.5px;
                padding-bottom: 7.5px;

                color: rgba(255, 255, 255, 1);
                background-color: rgba(117, 117, 117, 1);
                text-align: center;

                & + * {
                    padding: .75rem 1rem;
                    background-color: rgba(117, 117, 117, 1);

                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                }

                & > span {
                    font-size: 22px;
                    line-height: 22px;
                    font-weight: bold;
                }
            }

            & > .content {
                flex: 1;

                & > .item {
                    position: relative;
                    padding: .75rem 1rem;

                    & > .item-icon {
                        position: absolute;
                        right: 10px;
                        top: 0;
                        bottom: 0;

                        display: flex;
                        align-items: center;
                    }
                }

                & > .panel {
                    max-height: 0;
                    transition: max-height 500ms ease-in-out;
                    overflow: hidden;
                    margin-left: 15px;

                    & > .panel-block {
                        border: none;

                        & > .content {
                            padding: .75rem 1rem;
                        }
                    }

                    &.is-active {
                        max-height: 1000px;
                    }
                }
            }

            &.is-active {
                & > .content {
                    border-left-color: rgba(117, 117, 117, 1);
                    border-left-style: solid;
                    border-left-width: 6px;
                    background-color: rgba(255, 255, 255, 1);

                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

                    & > .item {
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

}
</style>
