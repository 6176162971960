import getSalon from "./getSalon";
import updateSalon from "./updateSalon";

import addCategory from "./addCategory";
import updateCategory from "./updateCategory";
import deleteCategory from "./deleteCategory";

import addSeries from "./addSeries";
import updateSeries from "./updateSeries";
import deleteSeries from "./deleteSeries";

import addTreatment from "./addTreatment";
import updateTreatment from "./updateTreatment";
import deleteTreatment from "./deleteTreatment";

import getOrders from "./getOrders";

import updateFAQs from "./updateFAQs";

export default {
    getSalon,
    updateSalon,

    addCategory,
    updateCategory,
    deleteCategory,

    addSeries,
    updateSeries,
    deleteSeries,

    addTreatment,
    updateTreatment,
    deleteTreatment,

    getOrders,

    updateFAQs,
}
