<template>
    <section class="log-in-column is-flex"
             v-bind:class="{ 'logged-in': status === 'loggedIn' }">
        <div class="background"></div>
        <form class="sign-in-form"
              v-on:submit="login">
            <h1 class="is-size-2">
                Welcome back...
            </h1>

            <b-field label="Username">
                <b-input type="email"
                         v-model="username">
                </b-input>
            </b-field>
            <b-field label="Password">
                <b-input type="password" password-reveal
                         v-model="password">
                </b-input>
            </b-field>

            <button class="button is-medium is-dark is-fullwidth"
                    v-bind:class="{ 'is-loading': status === 'loggingIn' }">
                Sign In
            </button>
        </form>
    </section>
</template>

<script>
    export default {
        name: 'LogInColumn',
        data() {
            return {
                username: null,
                password: null
            }
        },
        computed: {
            status() {
                return this.$store.state.admin.status
            }
        },
        methods: {
            login(evt) {
                evt.preventDefault()
                this.$store.dispatch('admin/login', {
                    email: this.username,
                    password: this.password
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .log-in-column {
        position: relative;
        height: 100%;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        box-shadow: 0 0 rgba(117, 117, 117, 1), -14px 0 rgba(117, 117, 117, 1);
        transition: transform 500ms ease-in-out;

        &.logged-in {
            transform: translateX(-100vw);
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;

            background-color: hsla(0, 0%, 100%, .5);
            backdrop-filter: blur(3px);
        }

        .sign-in-form {
            max-width: 330px;
            margin: 0 auto;
            padding: 0 15px;

            & > h1 {
                font-weight: bold;
                margin-bottom: 15px;
                line-height: 45px;
            }
        }
    }
</style>
