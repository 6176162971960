import { apiUrl, apiVersion } from "../../config";

export async function deleteCategory (
    categoryId
) {
    const token = window.localStorage.getItem("marier.admin-token")
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon/category/${ categoryId }`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        const category = await response.json()
        if (category) {
            return category;
        }
        throw "PRODUCT DELETE error";
    }
    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default deleteCategory;
