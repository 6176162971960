import Fuse from "fuse.js";

import getShop from "../api/shop/getShop";
import updateShop from "../api/shop/updateShop";

import getOrders from "../api/shop/getOrders";

import addCategory from "../api/shop/addCategory";
import updateCategory from "../api/shop/updateCategory";
import deleteCategory from "../api/shop/deleteCategory";

import addSeries from "../api/shop/addSeries";
import updateSeries from "../api/shop/updateSeries";
import deleteSeries from "../api/shop/deleteSeries";

import addProduct from "../api/shop/addProduct";
import updateProduct from "../api/shop/updateProduct";
import deleteProduct from "../api/shop/deleteProduct";

import addDeliveryClass from "../api/shop/addDeliveryClass";
import updateDeliveryClass from "../api/shop/updateDeliveryClass";
import deleteDeliveryClass from "../api/shop/deleteDeliveryClass";

import addBranch from "../api/mall/addBranch";
import updateBranch from "../api/mall/updateBranch";
import deleteBranch from "../api/mall/deleteBranch";

const ShopStore = {
    namespaced: true,
    state: {
        categories: [],
        series: [],
        products: [],
        branches: [],
        deliveryClasses: [],
        paymentMethods: undefined,
        notificationOptions: undefined,

        orders: [],
    },
    getters: {
        products (state) {
            return state.products;
        },
        productFuse (state) {
            return new Fuse(
                state.products,
                {
                    "keys": [
                        "title.en",
                        "title.zh-hk",
                        "title.zh-cn",
                    ],
                }
            );
        },
        series (state) {
            return state.series;
        },
        categories (state) {
            return state.categories;
        },
        branches (state) {
            return state.branches;
        },
        orders (state) {
            return state.orders;
        },
        orderFuse (state) {
            return new Fuse(
                state.orders,
                {
                    "keys": [
                        "owner",
                        "title.en",
                        "title.zh-hk",
                        "title.zh-cn",
                        "subtitle.en",
                        "subtitle.zh-hk",
                        "subtitle.zh-cn",
                        "items.title.en",
                        "items.title.zh-hk",
                        "items.title.zh-cn"
                    ]
                }
            );
        },
    },
    mutations: {
        setShop(
            state,
            { categories, series, products, branches, deliveryClasses, paymentMethods, notificationOptions,  }
        ) {
            state.categories = categories;
            state.series = series;
            state.products = products;
            state.branches = branches;
            state.deliveryClasses = deliveryClasses;
            state.paymentMethods = paymentMethods;
            state.notificationOptions = notificationOptions;
        },

        setOrders (
            state,
            orders
        ) {
            state.orders = orders;
        },

        updateShop (
            state,
            { categories, series, products, branches, deliveryClasses, paymentMethods, notificationOptions, }
        ) {
            state.categories = categories;
            state.series = series;
            state.products = products;
            state.branches = branches.map(
                branch => {
                    branch.coordinate.lat = parseFloat(branch.coordinate.lat);
                    branch.coordinate.lng = parseFloat(branch.coordinate.lng);
                    return branch;
                }
            );
            state.deliveryClasses = deliveryClasses;
            state.paymentMethods = paymentMethods;
            state.notificationOptions = notificationOptions;
        },

        addCategory (
            state,
            category
        ) {
            state.categories.push(category);
        },

        updateCategory (
            state,
            category
        ) {
            state.categories
                .splice(
                    state.categories
                        .findIndex(
                            ({ _id }) => _id === category._id
                        ),
                    1,
                    category
                );
        },

        deleteCategory (
            state,
            categoryId
        ) {
            state.categories
                .splice(
                    state.categories
                        .findIndex(
                            ({ _id }) => _id === categoryId
                        ),
                    1
                );
        },

        addSeries(
            state,
            series
        ) {
            state.series
                .push(
                    series
                );
        },

        updateSeries(
            state,
            updatedSeries
        ) {
            state.series
                .splice(
                    state.series
                        .findIndex(
                            ({ _id }) => _id === updatedSeries._id
                        ),
                    1,
                    updatedSeries
                );
        },

        deleteSeries(
            state,
            seriesId
        ) {
            state.series
                .splice(
                    state.series
                        .findIndex(
                            ({ _id }) => _id === seriesId
                        ),
                    1
                );
        },

        setProducts(state, products) {
            state.products = products
        },

        addProduct(state, product) {
            state.products
                .push(
                    product
                );
        },

        updateProduct(state, updatedProduct) {
            state.products
                .splice(
                    state.products
                        .findIndex(
                            ({ _id }) => _id === updatedProduct._id
                        ),
                    1,
                    updatedProduct
                );
        },

        deleteProduct(state, productId) {
            state.products
                .splice(
                    state.products
                        .findIndex(
                            ({ _id }) => _id === productId
                        ),
                    1
                );
        },

        addBranch (
            state,
            branch
        ) {
            branch.coordinate.lat = parseFloat(branch.coordinate.lat);
            branch.coordinate.lng = parseFloat(branch.coordinate.lng);
            state.branches
                .push(
                    branch
                );
        },

        updateBranch (
            state,
            updatedBranch
        ) {
            state.branches
                .splice(
                    state.branches
                        .findIndex(
                            ({ _id }) => _id === updatedBranch._id
                        ),
                    1,
                    updatedBranch
                );
        },

        deleteBranch(state, branchId) {
            state.branches
                .splice(
                    state.branches
                        .findIndex(
                            ({ _id }) => _id === branchId
                        ),
                    1
                );
        },

        addDeliveryClass(state, deliveryClasses) {
            state.deliveryClasses
                .push(
                    deliveryClasses
                );
        },

        updateDeliveryClass(state, updatedDeliveryClass) {
            state.deliveryClasses
                .splice(
                    state.deliveryClasses
                        .findIndex(
                            ({ _id }) => _id === updatedDeliveryClass._id
                        ),
                    1,
                    updatedDeliveryClass
                );
        },

        deleteDeliveryClass(state, deliveryClassId) {
            state.deliveryClasses
                .splice(
                    state.deliveryClasses
                        .findIndex(
                            ({ _id }) => _id === deliveryClassId
                        ),
                    1
                );
        }
    },
    actions: {
        async loadShop (
            { commit, },
        ) {
            let shop;
            try {
                shop = await getShop();
            } catch (e) {
                throw e;
            }
            commit(
                "setShop",
                shop
            );
            commit(
                "setProducts",
                shop.products
            );
            return shop;
        },

        async loadOrders (
            { commit, },
        ) {
            let orders;
            try {
                orders = await getOrders();
            } catch (e) {
                throw e;
            }
            commit("setOrders", orders)
            return orders;
        },

        async updateShop(
            { commit, },
            shop
        ) {
            let result
            try {
                result = await updateShop(
                    shop
                );
            } catch (e) {
                throw e;
            }
            commit("updateShop", result);
        },

        async addCategory(
            { commit, },
            category
        ) {
            let result
            try {
                result = await addCategory(
                    category
                );
            } catch (e) {
                throw e;
            }
            commit("addCategory", result);
        },

        async updateCategory (
            { commit },
            category
        ) {
            const {
                _id,
                ...rest
            } = category;

            let result
            try {
                result = await updateCategory(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateCategory", result);
        },

        async deleteCategory (
            { commit, },
            categoryId
        ) {
            try {
                await deleteCategory(
                    categoryId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteCategory", categoryId);
        },

        async addSeries (
            { commit, },
            series
        ) {
            let result
            try {
                result = await addSeries(
                    series
                );
            } catch (e) {
                throw e;
            }
            commit("addSeries", result);
        },

        async updateSeries (
            { commit, },
            series
        ) {
            const {
                _id,
                ...rest
            } = series;
            let result
            try {
                result = await updateSeries(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateSeries", result);
        },

        async deleteSeries (
            { commit, },
            seriesId
        ) {
            let result
            try {
                result = await deleteSeries(
                    seriesId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteSeries", seriesId);
        },

        async addProduct (
            { commit, },
            product
        ) {
            let result
            try {
                result = await addProduct(
                    product
                );
            } catch (e) {
                throw e;
            }
            commit("addProduct", result);
        },

        async updateProduct (
            { commit, },
            product
        ) {
            const {
                _id,
                ...rest
            } = product;
            let result
            try {
                result = await updateProduct(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateProduct", result);
        },

        async deleteProduct (
            { commit, },
            productId
        ) {
            try {
                await deleteProduct(
                    productId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteProduct", productId);
        },

        async addBranch (
            { commit, },
            branch
        ) {
            let result
            try {
                result = await addBranch(
                    branch
                );
            } catch (e) {
                throw e;
            }
            commit("addBranch", result);
        },

        async updateBranch (
            { commit, },
            branch
        ) {
            const {
                _id,
                ...rest
            } = branch;
            let result
            try {
                result = await updateBranch(
                    _id,
                    rest
                );
            } catch (e) {
                throw e;
            }
            commit("updateBranch", result);
        },

        async deleteBranch (
            { commit, },
            branchId
        ) {
            try {
                await deleteBranch(
                    branchId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteBranch", branchId);
        },

        async addDeliveryClass (
            { commit, },
            deliveryClass
        ) {
            let result
            try {
                result = await addDeliveryClass(
                    deliveryClass
                );
            } catch (e) {
                throw e;
            }
            commit("addDeliveryClass", result);
        },

        async updateDeliveryClass (
            { commit, },
            deliveryClass
        ) {
            const {
                _id,
                ...rest
            } = deliveryClass;
            let result
            try {
                result = await updateDeliveryClass(
                    _id,
                    rest
                )
            } catch (e) {
                throw e;
            }
            commit("updateDeliveryClass", result);
        },

        async deleteDeliveryClass (
            { commit, },
            deliveryClassId
        ) {
            try {
                await deleteDeliveryClass(
                    deliveryClassId
                );
            } catch (e) {
                throw e;
            }
            commit("deleteDeliveryClass", deliveryClassId);
        }
    }
}

export default ShopStore
