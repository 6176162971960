import { apiUrl, apiNewVersion } from "../../config";

export async function deleteCoupon (
    couponId
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiNewVersion }/coupon/${ couponId }`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        return;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default deleteCoupon;
