<template>
    <div id="app">
        <router-view></router-view>
        <notifications></notifications>
    </div>
</template>

<script>
    import Notifications from "./components/Notifications";
    export default {
        components: {
            Notifications
        },
        computed: {
            userStatus () {
                return this.$store.state.admin.status;
            },
        },
        created () {
            this.$store.dispatch("admin/loadToken");
        },
        mounted () {
            window.addEventListener(
                "online",
                (evt) => {
                    console.log(evt);
                    this.$store.commit("layout/online");
                }
            );

            window.addEventListener(
                "offline",
                (evt) => {
                    this.$store.commit("layout/offline");
                }
            );
        },
        watch: {
            userStatus (newVal) {
                if (newVal === "loggedIn") {
                    this.$store.dispatch("admin/loadAdmins");
                    this.$store.dispatch("users/loadUsers");
                    this.$store.dispatch("mall/loadMall");

                    this.$store.dispatch("shop/loadShop");
                    this.$store.dispatch("salon/loadSalon");

                    this.$store.dispatch("coupon/loadCoupon");

                    this.$store.dispatch("shop/loadOrders");
                    this.$store.dispatch("salon/loadOrders");

                    this.$store.dispatch("mall/loadCampaigns");

                    this.$store.dispatch("mall/loadNews");
                    this.$store.dispatch("mall/loadBlog");
                    this.$store.dispatch("mall/loadMessage");

                }
            },
        }
    }
</script>

<style lang="scss">
    html,
    body {
        height: 100%;
        width: 100%;
        overflow: auto !important;
    }

    /**
     * Buefy Setup
     */
    @import "~bulma/sass/utilities/_all";

    /*$primary: #00d1b2;
    $primary-invert: findColorInvert($primary);*/

    $marier: rgba(113, 113, 113, 1);
    $marier-invert: findColorInvert($marier);

    $primary: #8c67ef;
    $primary-invert: findColorInvert($primary);

    $twitter: #4099FF;
    $twitter-invert: findColorInvert($twitter);

    $colors: (
        "white": ($white, $black),
        "black": ($black, $white),
        "light": ($light, $light-invert),
        "dark": ($dark, $dark-invert),
        "primary": ($primary, $primary-invert),
        "info": ($info, $info-invert),
        "success": ($success, $success-invert),
        "warning": ($warning, $warning-invert),
        "danger": ($danger, $danger-invert),
        "twitter": ($twitter, $twitter-invert),
        "marier": ($marier, $marier-invert)
    );

    $link: $primary;
    $link-invert: $primary-invert;
    $link-focus-border: $primary;

    @import "~bulma";
    @import "~buefy/src/scss/buefy";
    /*******************************************/

    //@import "../node_modules/vue2-animate/src/sass/vue2-animate.scss";

    @import "~medium-editor/dist/css/medium-editor.min.css";
    @import "~medium-editor/dist/css/themes/default.min.css";

    #app {
        height: 100%;
        width: 100%;
    }

    .ql-editor iframe {
        pointer-events: none;
    }

    .hero {
        position: relative;

        & > .bg-filter {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            backdrop-filter: blur(4px);
        }
    }

    .modal {
        &.is-active {
            display: flex !important;
        }
    }

    .view {
        background-color: rgba(245, 245, 245, 1);
        height: calc(100% - 53px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
</style>
