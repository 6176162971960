import { apiUrl, apiVersion } from "../../config";

export async function updateMall (
    update
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall`,
        {
            "method": "PATCH",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            "body": JSON.stringify(
                update
            ),
        }
    );

    if (response.ok) {
        const mall = await response.json();
        if (mall) {
            return mall;
        }
        throw "MALL PATCH error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateMall;
