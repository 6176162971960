import { apiUrl, apiVersion } from "../../config";

export async function updateSalon (
    salon
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(
                salon
            )
        }
    );

    if (response.ok) {
        const salon = await response.json()
        if (salon) {
            return salon;
        }
        throw "SALON UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateSalon;
