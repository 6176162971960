import {
    library
} from "@fortawesome/fontawesome-svg-core";

import {
    faUsersClass as faUsersClassSolid,
    faInfoSquare as faInfoSquareSolid,
    faEmptySet as faEmptySetSolid,

    faBold as faBoldSolid,
    faItalic as faItalicSolid,
    faStrikethrough as faStrikethroughSolid,
    faUnderline as faUnderlineSolid,
    faCode as faCodeSolid,
    faParagraph as faParagraphSolid,
    faBracketsCurly as faBracketsCurlySolid,
    faHorizontalRule as faHorizontalRuleSolid,
    faQuoteLeft as faQuoteLeftSolid,
    faListOl as faListOlSolid,
    faListUl as faListUlSolid,

    faH1 as faH1Solid,
    faH2 as faH2Solid,
    faH3 as faH3Solid,
    faH4 as faH4Solid,

    faRedo as faRedoSolid,
    faUndo as faUndoSolid,

    faAngleDown as faAngleDownSolid,
    faBriefcase,
    faShoppingBasket,
    faSignInAlt,
    faUserEdit,
    faGlobe as faGlobeSolid,
    faCompactDisc,
    faSignOutAlt,
    faCheckCircle,
    faCog as faCogSolid,
    faHome as faHomeSolid,
    faUnlockAlt,
    faBoxes,
    faTags as faTagsSolid,
    faGift as faGiftSolid,
    faEdit as faEditSolid,
    faTrashAlt as faTrashAltSolid,
    faUser as faUserSolid,
    faPlus as faPlusSolid,
    faFileInvoiceDollar as faFileInvoiceDollarSolid,
    faThumbtack as faThumbtackSolid,
    faDollarSign as faDollarSignSolid,
    faSave as faSaveSolid,
    faImage as faImageSolid,
    faGripHorizontal as faGripHorizontalSolid,
    faInfoCircle as faInfoCircleSolid,
    faUserSecret as faUserSecretSolid,
    faFileContract as faFileContractSolid,
    faMinusCircle as faMinusCircleSolid,
    faQuestion as faQuestionSolid,
    faTruck as faTruckSolid,
    faPalette as faPaletteSolid,
    faPrint as faPrintSolid,
    faStickyNote as faStickyNoteSolid,
    faVideo as faVideoSolid,
    faRedoAlt as faRedoAltSolid,
    faUserTag as faUserTagSolid,
    faDownload as faDownloadSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faPeople as faPeopleSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faCalendarAlt as faCalendarAltRegular,
    faComments as faCommentsRegular,
    faCommentDots as faCommentDotsRegular,
    faUser as faUserRegular
} from "@fortawesome/free-regular-svg-icons";

import {
    faCheck as faCheckRegular,
    faTimes as faTimesRegular,
    faArrows as faArrowsRegular,
    faSearch as faSearchRegular
} from "@fortawesome/pro-regular-svg-icons";

import {
    faPlus as faPlusLight,
    faImage as faImageLight,
    faChevronDown as faChevronDownLight
} from "@fortawesome/pro-light-svg-icons";

import {
    faSortUp as faSortUpDuotone,
    faSortDown as faSortDownDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

import {
    faFacebookSquare,
    faInstagram,
    faWeixin,
    faWeibo,
    faGoogle,
    faWhatsapp,
    faTwitter,
    faLine,
    faPaypal,
    faAlipay,
    faTelegramPlane
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faAngleDownSolid,
    faBriefcase,
    faShoppingBasket,
    faSignInAlt,
    faUserEdit,
    faCalendarAltRegular,
    faGlobeSolid,
    faCompactDisc,
    faSignOutAlt,
    faCommentsRegular,
    faCommentDotsRegular,
    faUserSolid,
    faUserRegular,
    faCheckCircle,
    faCogSolid,
    faHomeSolid,
    faUnlockAlt,
    faBoxes,
    faUsersClassSolid,
    faTagsSolid,
    faGiftSolid,
    faEditSolid,
    faTrashAltSolid,
    faCheckRegular,
    faTimesRegular,
    faPlusSolid,
    faInfoSquareSolid,
    faFileInvoiceDollarSolid,
    faThumbtackSolid,
    faDollarSignSolid,
    faPlusLight,
    faEmptySetSolid,

    faBoldSolid,
    faItalicSolid,
    faStrikethroughSolid,
    faUnderlineSolid,
    faCodeSolid,
    faParagraphSolid,
    faBracketsCurlySolid,
    faHorizontalRuleSolid,
    faQuoteLeftSolid,
    faListOlSolid,
    faListUlSolid,

    faH1Solid,
    faH2Solid,
    faH3Solid,
    faH4Solid,

    faRedoSolid,
    faUndoSolid,

    faArrowsRegular,
    faSearchRegular,
    faSaveSolid,
    faImageSolid,
    faImageLight,
    faGripHorizontalSolid,
    faInfoCircleSolid,
    faUserSecretSolid,
    faFileContractSolid,
    faMinusCircleSolid,
    faChevronDownLight,
    faQuestionSolid,
    faTruckSolid,
    faPaletteSolid,
    faPrintSolid,
    faStickyNoteSolid,
    faVideoSolid,
    faFacebookSquare,
    faInstagram,
    faWeixin,
    faWeibo,
    faGoogle,
    faWhatsapp,
    faTwitter,
    faLine,
    faPaypal,
    faAlipay,
    faTelegramPlane,
    faRedoAltSolid,
    faUserTagSolid,
    faDownloadSolid,
    faMagnifyingGlassSolid,
    faPeopleSolid,
    faSortUpDuotone,
    faSortDownDuotone,
);
