import { apiUrl, apiVersion } from "../../config";

export async function updateProduct (
    productId,
    product
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const body = new FormData();
    for (const name in product) {
        if (["cover"].includes(name)) {
            if (product[name]) {
                body.append(`${ name }`, product[name])
            }
        }
        if (["images"].includes(name)) {
            if (product[name]) {
                body.append(`${ name }`, product[name][0])
            }
        } else {
            if (product[name] !== undefined) {
                body.append(name, JSON.stringify(product[name]))
            }
        }
    }
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/product/${ productId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${ token }`
            },
            body,
        }
    )

    if (response.ok) {
        const product = await response.json();
        if (product) {
            return product;
        }
        throw "PRODUCT ADD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateProduct;
