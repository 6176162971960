import { apiUrl, apiVersion } from "../../config";

export async function getMessages (
    start,
    count
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const searchParams = new URLSearchParams({  start, count, });
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall/inbox?${ searchParams.toString() }`,
        {
            "method": "GET",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`,
            }
        }
    );

    if (response.ok) {
        const messages = await response.json();
        if (messages) {
            return messages;
        }
        throw "MESSAGES GET error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getMessages;
