<template>
    <div class="notifications">
        <b-notification class="notification-update-available"
                        type="is-warning"
                        has-icon
                        position="is-top-right"
                        v-bind:closable="false"
                        v-bind:active.sync="updateAvailable">
            <p>
                An update is available. Click to update 👇👇
            </p>
            <button class="button is-small"
                    v-on:click="updateApp">
                Update
            </button>
        </b-notification>
    </div>
</template>

<script>
    export default {
        name: "Notifications",
        data () {
            return {

            };
        },
        computed: {
            updateAvailable () {
                console.log(this.$store.state.layout.updateAvailable);
                return this.$store.state.layout.updateAvailable;
            },
        },
        methods: {
            updateApp () {
                if (navigator.serviceWorker) {
                    if (this.$store.state.layout.updatedServiceWorker) {
                        let refreshing = false;
                        navigator.serviceWorker
                            .addEventListener(
                                "controllerchange",
                                () => {
                                    if (refreshing) {
                                        return;
                                    }
                                    window.location.reload();
                                    refreshing = true;
                                }
                            );

                        this.$store.state.layout.updatedServiceWorker.postMessage({
                            type: "SKIP_WAITING"
                        });
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .notifications {
        position: fixed;
        top: 15px;
        right: 15px;

        .notification-update-available {
            p {
                margin-bottom: 0.5rem;
            }

            button {
                display: block;
                margin-left: auto;
            }
        }
    }
</style>
