import { apiUrl, apiVersion } from "../../config";

export async function getAdmins () {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${apiUrl}/${apiVersion}/admin?_id=true&email=true&role=true&verified=true`,
        {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${ token }`
            }
        }
    );

    if (response.ok) {
        return await response.json();
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getAdmins;
