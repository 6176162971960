import { apiUrl, apiVersion } from "../../config";

export async function updateDeliveryClass (
    deliveryClassId,
    deliveryClass
) {
    const token = window.localStorage.getItem("marier.admin-token")
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop/delivery/${deliveryClassId}`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            body: JSON.stringify(
                deliveryClass
            )
        }
    )

    if (response.ok) {
        const deliveryClass = await response.json();
        if (deliveryClass) {
            return deliveryClass;
        }
        throw "DELIVERY CLASS UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateDeliveryClass;
