<template>
    <main class="dashboard columns is-gapless"
          v-bind:class="{ 'active': status === 'loggedIn' }">
        <side-bar class="column"></side-bar>
        <div class="column is-10 is-11-desktop">
            <nav-bar></nav-bar>
            <router-view></router-view>
        </div>
    </main>
</template>

<script>
    import NavBar from '../components/layouts/NavBar'
    import SideBar from '../components/layouts/SideBar'

    export default {
        name: 'Dashboard',
        components: {
            SideBar,
            NavBar
        },
        computed: {
            status() {
                return this.$store.state.admin.status
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dashboard {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        right: -100vw;
        transition: right 500ms ease-in-out;
        background-color: rgba(255, 255, 255, 1);

        &.active {
            right: 0;
        }

        .column {
            &.is-four-fifths {
                z-index: 2;
            }
        }
    }
</style>
