import authToken from "../api/admin/authToken";
import getAdmins from "../api/admin/getAdmins";
import login from "../api/admin/login";
import logout from "../api/admin/logout";

const AdminStore = {
    namespaced: true,
    state: {
        status: "loggedOut",
        message: "",
        loggedIn: false,
        loginFailed: false,
        registerFailed: false,
        token: "",
        user: {},

        admins: []
    },
    mutations: {
        resetStatus(state) {
            state.status = "loggedOut";
        },

        setLoggingIn(state) {
            state.status = "loggingIn";
        },

        setLoginSuccess(state) {
            state.status = "loggedIn";
        },

        setLoginFailed(state) {
            state.status = "loginFailed";
        },

        setRegistering(state) {
            state.status = "registering";
        },

        setRegisterFailed(state) {
            state.status = "registerFailed";
        },

        setMessage(state, message) {
            state.message = message;
        },

        unsetMessage(state) {
            state.message = "";
        },

        setUser(state, user) {
            let temp = Object.assign({}, user);
            delete temp.__v;
            state.user = temp;
        },

        setAdmins(state, admins) {
            state.admins = admins;
        }
    },
    actions: {
        async loadToken(context) {
            const token = window.localStorage.getItem("marier.admin-token")
            if (token) {
                let user
                try {
                    user = await authToken(
                        token
                    );
                } catch (e) {
                    window.localStorage.setItem("marier.admin-token", "")
                    throw e;
                }
                context.commit("setMessage", "");
                context.commit("setUser", user);
                context.commit("setLoginSuccess", true);
                return token;
            }
            throw "No token";
        },

        async loadAdmins (
            { commit, },
        ) {
            let admins
            try {
                admins = await getAdmins();
            } catch (e) {
               throw e;
            }
            commit("setAdmins", admins);
            return admins;
        },

        async register (
            { commit, },
            payload
        ) {
            commit("setRegistering");
            commit("setMessage", "");
            let user
            try {
                user = await register(
                    payload
                );
            } catch (e) {
                context.commit("setRegisterFailed");
                context.commit("setMessage", e.reason);
                throw e;
            }

            context.commit("setLoggingIn");
            let token
            try {
                token = await login(
                    payload
                );
            } catch (e) {
                commit("setLoginFailed");
                commit("setMessage", e.reason);
                throw e;
            }
            user = await authToken(token);
            context.commit("setUser", user);
            context.commit("setLoginSuccess");
            window.localStorage.setItem("marier.admin-token", token);
            return user;
        },

        async login (
            { commit, },
            payload
        ) {
            commit("setLoggingIn");
            commit("setMessage", "");
            let token
            try {
                token = await login(
                    payload
                );
            } catch (e) {
                commit("setLoginFailed");
                commit("setMessage", e.reason);
                throw e
            }
            const user = await authToken(
                token
            );
            commit("setUser", user);
            commit("setLoginSuccess");
            window.localStorage.setItem("marier.admin-token", token);
            return user;
        },

        logout (
            { commit, },
        ) {
            logout();
            commit("setMessage", "");
            commit("resetStatus");
        },

        setMessage (
            { commit, },
            message
        ) {
            commit("setMessage", message);
        },

        unsetMessage (
            { commit, },
        ) {
            commit("unsetMessage");
        }
    }
}

export default AdminStore
