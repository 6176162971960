import UsersAPI from "../api/users"

const UsersStore = {
    namespaced: true,
    state: {
        users: [],
    },
    getters: {
        items ({ users }) {
            return users;
        },
    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        },
        addUser(state, user) {
            state.users.push(user)
        },
        editUser(state, newUser) {
            state.users.splice(state.users.findIndex(user => user._id === newUser._id), 1, newUser)
        },
        deleteUser(state, userId) {
            state.users.splice(state.users.findIndex(user => user._id === userId), 1)
        },
    },
    actions: {
        async loadUsers(
            { commit }
        ) {
            let users;
            try {
                users = await UsersAPI
                    .getUsers();
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("setUsers", users);
            return users;
        },

        async addUser(
            context,
            newUser
        ) {

        },

        async editUser(
            { commit },
            updatedUser
        ) {
            let user;
            try {
                user = await UsersAPI
                    .editUser(
                        updatedUser
                    );
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("editUser", user);
            return user;
        },

        async deleteUser(
            { commit },
            userId
        ) {
            try {
                await UsersAPI
                    .deleteUser(
                        userId
                    );
            } catch (e) {
                console.error(e);
                throw e;
            }
            commit("deleteUser", userId);
        },
    }
};

export default UsersStore;
