import { apiUrl, apiVersion } from "../../config";

export async function addSeries (
    series
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const body = new FormData();
    for (const name in series) {
        if (["cover", "image", "background_description", "background_product"].includes(name)) {
            if (series[name]) {
                body.append(`${name}`, series[name]);
            }
        } else {
            if (series[name] !== undefined) {
                body.append(name, JSON.stringify(series[name]));
            }
        }
    }
    const response = await fetch(
        `${apiUrl}/${apiVersion}/shop/series`,
        {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${ token }`
            },
            body,
        }
    );

    if (response.ok) {
        const series = await response.json();
        if (series) {
            return series;
        }
        throw "Series ADD error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default addSeries;
