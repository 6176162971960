import {formatRelative} from 'date-fns'
import {enGB} from 'date-fns/locale'

const dateFnsRelativeFormatter = (
    value,
    baseDate = new Date(),
    options = {locale: enGB}
) => {
    if (!value) {
        return ''
    }

    if (typeof value === 'string') {
        value = new Date(value)
    }

    if (typeof baseDate === 'string') {
        baseDate = new Date(baseDate)
    }

    if (
        !(value instanceof Date) &&
        typeof value !== 'number'
    ) {
        return ''
    }

    if (!(baseDate instanceof Date) &&
        typeof baseDate !== 'number'
    ) {
        return ''
    }

    return formatRelative(value, baseDate, options)
}

let vueDateFnsRelativeFormatter = {
    install: function (Vue, options) {
        Vue.filter(
            'dateFnsRelativeFormatter',
            dateFnsRelativeFormatter
        )
    }
}

export {dateFnsRelativeFormatter}
export default vueDateFnsRelativeFormatter
