import { apiUrl, apiVersion } from "../../config";

export async function deleteTreatment(
    treatmentId
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/salon/treatment/${ treatmentId }`,
        {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
    );

    if (response.ok) {
        let treatment = await response.json();
        if (treatment) {
            return treatment;
        }
        throw "Treatment DELETE error";
    }
    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default deleteTreatment;
