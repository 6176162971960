import { apiUrl, apiVersion } from "../../config";

export async function updateBlog (
    blogId,
    blog
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const body = new FormData();
    for (const name in blog) {
        if (["thumbnail", "titleImage", "images"].includes(name)) {
            if (blog[name]) {
                body.append(name, blog[name]);
            }
        } else {
            if (blog[name] !== undefined) {
                body.append(name, JSON.stringify(blog[name]));
            }
        }
    }

    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall/blog/${ blogId }`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Authorization": `Bearer ${ token }`
            },
            body,
        }
    )

    if (response.ok) {
        const blog = await response.json();
        if (blog) {
            return blog;
        }
        throw "BLOG UPDATE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateBlog;
