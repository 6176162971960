import { apiUrl, apiVersion } from "../../config";

export async function updateShop (
    shop
) {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${apiUrl}/${apiVersion}/shop`,
        {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(shop)
        }
    );

    if (response.ok) {
        const shop = await response.json();
        if (shop) {
            return shop
        }
        throw "SHOP UPDATE error";
    }
    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateShop;
