import { apiUrl, apiVersion } from "../../config";

export async function getShop () {
    const token = window.localStorage.getItem("marier.admin-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/shop`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }
    );

    if (response.ok) {
        const shop = await response.json();
        if (shop) {
            shop.branches.forEach(
                branch => {
                    branch.coordinate.lat = parseFloat(branch.coordinate.lat);
                    branch.coordinate.lng = parseFloat(branch.coordinate.lng);
                }
            )
            return shop;
        }
        throw "SHOP GET error";
    }
    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getShop;
